import React, { useEffect } from "react";
import { standardAuthConfig } from "../src/utils/constants/standard-auth-config";
import { useSession } from "next-auth/react";
import { useReduxSelector } from "../src/store/selectors";
import { getUser } from "../src/store/selectors/user.selectors";
import { useRouter } from "next/router";

export default function Docs() {
    // The real implementation would just load all of the MetaInfo and pass it as contacts
    const { data: session, status } = useSession();
    const user = useReduxSelector(getUser);
    const router = useRouter();

    useEffect(() => {
        console.log("Index checker: " + status);
        console.log(user.isOnboarded, user.email, session.user.email);
        if (status === "unauthenticated") {
            (async () => await router.push("/login"))();
        } else if (status === "authenticated" && user.email === session.user.email) {
            if (!user.isOnboarded) {
                (async () => await router.push("/onboarding"))();
            } else if (!user.isActivated) {
                (async () => await router.push("/login"))();
            }
        }
    }, [status, user.isOnboarded, user.email, session.user.email]);
    return <div />;
}

Docs.authenticated = {
    ...standardAuthConfig
};
